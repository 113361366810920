#root {
  width: 100vw;
  height: 100vh;
}

body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.above__camera__container {
  color: white;
  font-size: 20px;
  background-color: black;
  justify-content: center;
  align-content: center;
  padding: 15px;

}


.Container {
  width: 65%;
  position: relative;
  left: 40%;
}

.container {
  display: flex;
  flex-direction: column;
  width: 40%;
  height: 80%;
}

.container-chat {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
  text-align: left;
}

.chat-Historyc {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 20%;
  width: 3.5vw;
  height: 3.5vw;
}

.chat-Historyo {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 4%;
  width: 30vw;
  height: 45vh;
}
/* ::placeholder {
  color: white;
  text-align: center;
  opacity: 1;
} */

.placeholder1::placeholder {
  color: white;
  opacity: 1;
}

.placeholder2::placeholder {
  color: rgba(219, 168, 66, 255);
  text-align: center;
}

.placeholder3::placeholder {
  color: white;
  opacity: 1;
  font-size: 15px;
}

/* width */
::-webkit-scrollbar {
  width: 0px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: transparent;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

.icon {
  position: relative;
  margin-left: 50px;
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  width: 13px;
  height: 13px;

  span {
    width: 3px;
    height: 100%;
    background-color: orange;
    border-radius: 3px;
    content: '';
  }
}

@keyframes bounce {
  10% {
    transform: scaleY(0.3); /* start by scaling to 30% */
  }

  30% {
    transform: scaleY(1); /* scale up to 100% */
  }

  60% {
    transform: scaleY(0.5); /* scale down to 50% */
  }

  80% {
    transform: scaleY(0.75); /* scale up to 75% */
  }

  100% {
    transform: scaleY(0.6); /* scale down to 60% */
  }
}

.span-prop {
  width: 3px;
  height: 100%;
  background-color: orange;
  border-radius: 3px;
  animation: bounce 2.2s ease infinite alternate;
  content: '';
}

span {
  &:nth-of-type(2) {
    animation-delay: -2.2s; /* Start at the end of animation */
  }

  &:nth-of-type(3) {
    animation-delay: -3.7s; /* Start mid-way of return of animation */
  }
}
.ChatBubble {
  position: absolute;
  top: 1vh;
  left: 1vw;
  z-index: 1;
}

table {
  border-collapse: collapse; /* Collapse borders for a cleaner look */
  text-align: center;
  margin: 0 auto;
  font-weight: bold;
  animation: dropTop 0.05s ease-in-out;

}

td {
  border: 1px solid transparent; /* Set border color to transparent */
  /* padding-right: 30px; Add padding for spacing 
  padding-left: 30px;
  padding-top: 10px;*/
  padding-right: 30px; 
  padding-left: 30px;
  padding-top: 10px;
  text-align: center;
  font-family: 'Dejavu Sans', sans-serif;
}

thead {
  font-family: "Dejavu Sans", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.zero-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

tbody {
  font-family: "Dejavu Sans", sans-serif;
  font-size: 20px;
}

button {
  background-color: white;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Dejavu Sans", sans-serif;
  font-size: 17px;
}

p {
  font-family: "Dejavu Sans", sans-serif;
}

.Popup {
  font-family: "Dejavu Sans", sans-serif;
  /*transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;*/
}

.modal-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-icon {
  cursor: pointer;
  color: #333; /* Adjust color as needed */
  font-size: 20px; /* Adjust size as needed */
}
/*.modal {
  opacity: 0;
  transform: scale(0.8);
  
}

.modal.open {
  opacity: 1;
  transform: scale(1);
  transition: opacity 2.5s cubic-bezier(0.8, 0, 0.2, 1), transform 2.5s ease-in-out;
  outline: 0;
}
  
.modal {
  opacity: 0;
  transform: scale(0.2); 
  transition: opacity 3.5s ease-in-out, transform 3.5s ease-in-out;
}

.modal.open {
  opacity: 1;
  transform: scale(1);
  transition: opacity 3.5s ease-in-out, transform 3.5s ease-in-out;
  outline: 0;
}
*/

@keyframes dropTop {
  0% {
    transform: translateY(-30%) scale(0);
    opacity: 0;
  }
  50% {
    opacity: 0.5; 
  }
  100% {
    transform: translateY(0) scale(1);
    opacity: 1;
  }
}

.close_button {
  position: absolute;
  top: 0;
  right: 0;
}
.modal-content {
  margin: 0 auto;
}

.tooltip {
  position: relative;
  top: 86%;
  right: 4%;
  /*transform: translate(-20%, -10px);*/
  padding: 2px 2px;
  background-color: rgba(0, 0, 0, 0.75);
  color: #fff;
  border-radius: 3px;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
  z-index: 999; /* Ensure it appears above other elements */
  font-size: 12px;
  animation: dropTop 0.1s ease-in-out;
  width: 110%;
}

p {
  font-family: "Dejavu Sans", sans-serif;
  white-space: pre-wrap;
}